import React from 'react';

import logo from '../Assets/images/logo.svg';

import '../Assets/css/loader.css';

function Loader() {

    return (
        <>
            <img src={logo} alt="Jesus Gonzalez" className="image-loader"/>
            <div className="loader">Loading...</div>
        </>
    );
}

export default Loader;
