import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';

import Loader from './Components/Loader';

import './Assets/css/bootstrap.min.css';
import './Assets/css/main.css';
import 'rodal/lib/rodal.css';

import * as serviceWorker from './serviceWorker';

const App = React.lazy(() => import('./App'));

ReactDOM.render(
    <Suspense fallback={<Loader />}>
        <App />
    </Suspense>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
